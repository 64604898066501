<template>
  <div class="asset-impairment-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="DetailInfo" :tab="$t(`减值明细`)"></a-tab-pane>
          <a-tab-pane key="Attachment" :tab="$t(`附件`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.orderStatus === 'WAIT_CHECK'"
            api="operateAssetImpairment"
            :text="$t('审核')"
            :params="{
              action: 'CHECK',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            class="ml10"
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_PAY'].includes(formData.orderStatus)"
            api="operateAssetImpairment"
            type="danger"
            :text="$t('作废')"
            :params="{
              action: 'DELETE',
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="isDetail && !['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.orderStatus)"
            :docType="docType"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox
              id="BaseInfo"
              :title="$t('基本信息')"
              :showCopy="isDetail"
              @copyOrder="copyOrder"
            >
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据编号`)">
                    <a-input disabled v-model="formData.orderCode"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`货主组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :placeholder="$t('货主组织')"
                      :disabled="isDisabled"
                      :code.sync="formData.ownerBusinessUnitCode"
                      :name.sync="formData.ownerBusinessUnitName"
                      @change="getCurrentAccountingPeriod"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`会计政策`)" required>
                    <CommonQuerySelect
                      :init="true"
                      :placeholder="$t('会计政策')"
                      api="getAccountingPolicyPage"
                      :disabled="isDetail"
                      :code.sync="formData.accountingPolicyCode"
                      :name.sync="formData.accountingPolicyName"
                      valueName="policy_code"
                      labelName="policy_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabledDate="disabledDate"
                      :disabled="isDisabled"
                      :placehold="$t(`记账日期`)"
                      @choose="chooseAccountingDate"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>

            <ModalBox id="DetailInfo" :title="$t('减值明细')">
              <template v-slot:header>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="showAssetCardModal">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml10" @click="deleteAssetCard">
                    {{ $t('删行') }}
                  </span>
                </div>
              </template>
              <CommonTable
                :showSelect="true"
                :selectedRowKeys.sync="selectedRowKeys"
                :columns="detailColumns"
                :dataSource="formData.detailList"
                :scroll="{ x: 1200 }"
                :showPagination="false"
                :showSetting="false"
              >
                <template v-slot:currentPeriodImpairment="{ record }">
                  <PriceInput :value.sync="record.currentPeriodImpairment" :disabled="isDisabled" />
                </template>
              </CommonTable>
            </ModalBox>

            <ModalBox id="Attachment" :title="$t('附件')">
              <div class="attachment-list">
                <Attachment ref="AttachmentRef" :list="fileList" :disabled="isDisabled" />
              </div>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.orderStatus"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <SelectAssetCardModal
      ref="AssetCardModal"
      assetOperateCode="IMPAIRMENT"
      :hasSelected="hasSelected"
      :ownerBusinessUnitCode="formData.ownerBusinessUnitCode"
      @ok="handleAssetCardOk"
    ></SelectAssetCardModal>
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import OperationLog from '@component/operationLog'
import SelectAssetCardModal from '@component/selectAssetCardModal'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase, goBack } from '@/common'
export default {
  name: 'AssetImpairmentDetail',
  components: { DatePicker, BottomBtns, OperationLog, SelectAssetCardModal },
  data() {
    return {
      docType: 'AssetImpairment',
      id: undefined,
      sn: undefined,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        orderStatus: 'DRAFT',
        accountingDate: undefined,
        ownerBusinessUnitCode: undefined,
        detailList: [],
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      isReturn: true,
      startDate: undefined,
      endDate: undefined,
      selectedRowKeys: [],
      fileList: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
    hasSelected() {
      return this.formData.detailList?.map((item) => item.id)
    },
    detailColumns() {
      let list = [
        {
          title: this.$t('资产编码'),
          dataIndex: 'assetCode',
        },
        {
          title: this.$t('资产名称'),
          dataIndex: 'assetName',
        },
        {
          title: this.$t('资产类别'),
          dataIndex: 'assetTypeName',
        },
        {
          title: this.$t('本期减值'),
          dataIndex: 'currentPeriodImpairment',
          scopedSlots: {
            customRender: 'currentPeriodImpairment',
          },
        },
        // {
        //   title: this.$t('折旧影响日期'),
        //   dataIndex: 'assetName',
        // },
      ]
      return list
    },
  },
  watch: {
    'formData.ownerBusinessUnitCode'(val, oldVal) {
      if (oldVal && val !== oldVal && !this.isReturn) {
        this.$confirm({
          title: this.$t('组织切换，将清除单据信息，是否确认切换？'),
          onOk: () => {
            this.formData.detailList = []
          },
          onCancel: () => {
            this.isReturn = true
            this.formData.ownerBusinessUnitName = undefined
            this.formData.ownerBusinessUnitCode = oldVal
          },
        })
      } else {
        this.isReturn = false
      }
    },
  },
  methods: {
    init() {
      this.isDetail = false
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    chooseAccountingDate(data) {
      this.formData.accountingDate = data.data
    },
    disabledDate(current) {
      return current && (current > moment(this.endDate) || current < moment(this.startDate))
    },
    async getCurrentAccountingPeriod() {
      let res = await http({
        url: api.getCurrentAccountingPeriod,
        data: {
          ownerBusinessUnitCode: this.formData.ownerBusinessUnitCode,
        },
      })
      this.startDate = res.result.start_date
      this.endDate = res.result.end_date
      this.formData.accountingDate = res.result.end_date
    },
    async getDetailInfo() {
      let assetImpairmentFormdata = localStorage.getItem('assetImpairmentFormdata')
      if (this.id) {
        this.loading = true
        await http({
          url: api.getAssetImpairmentDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            this.formData = deepClone(result)
            if (result.orderStatus === 'DRAFT') {
              this.isEdit = true
            }
            if (this.formData.attachmentList?.length > 0) {
              this.fileList = this.formData.attachmentList?.map((item) => {
                return {
                  uid: item.id,
                  name: item.fileName,
                  url: item.fileUrl,
                  status: 'done',
                }
              })
            }
          },
        })
        this.loading = false
      } else if (assetImpairmentFormdata) {
        //复制
        let data = JSON.parse(assetImpairmentFormdata)
        this.formData = data
        this.formData.orderCode = undefined
        this.formData.id = undefined
        this.formData.orderStatus = 'DRAFT'
        this.fileList = deepClone(this.formData.fileList)
        delete this.formData.fileList
        localStorage.removeItem('assetImpairmentFormdata')
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    copyOrder() {
      this.formData.fileList = this.fileList
      let data = JSON.stringify(this.formData)
      localStorage.setItem('assetImpairmentFormdata', data)
      this.$router.push({ name: 'assetImpairmentDetail' })
    },
    backForm(back = 0) {
      goBack('assetImpairmentList', back)
    },
    onSubmit: debounce(async function (status) {
      let attachmentList = await this.$refs.AttachmentRef.changeUpload()
      const data = deepClone(this.formData)
      data.attachmentList = attachmentList
      data.orderStatus = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.merchantCode = localStorage.getItem('merchantCode')
      await http({
        url: api.createAssetImpairment,
        data: {
          assetImpairment: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    showAssetCardModal() {
      this.$refs.AssetCardModal.open()
    },
    deleteAssetCard() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.formData.detailList = this.formData.detailList.filter(
        (item, index) => !this.selectedRowKeys.includes(index)
      )
      this.selectedRowKeys = []
    },
    handleAssetCardOk(list) {
      let errList = []
      list.forEach((item) => {
        if (moment(item.startUseDate).isAfter(this.formData.accountingDate)) {
          errList.push(item.cardNo)
        }
      })
      if (errList.length > 0) {
        this.$error({
          title: this.$t(`错误`),
          content: this.$t(`资产卡片X是未来期间入账，不允许进行当前业务`, {
            X: errList.join('、'),
          }),
        })
        return
      }
      list = list.map((item) => ({
        assetCardId: item.id,
        assetCardNo: item.cardNo,
        assetCode: item.assetCode,
        assetName: item.assetName,
        assetTypeCode: item.assetTypeCode,
        assetTypeName: item.assetTypeName,
        currentPeriodImpairment: '',
        merchantCode: localStorage.getItem('merchantCode'),
      }))
      this.formData.detailList = [...this.formData.detailList, ...list]
      this.$refs.AssetCardModal.close()
      // this.setDetailItem()
    },
  },
}
</script>
